import React from "react";
import {NavLink} from "react-router-dom";
import WelcomeSign from "../assets/welcome-sign.jpg"
import {paths} from "../misc/constant";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faBars} from "@fortawesome/free-solid-svg-icons"

import "./nav.scss";

class Nav extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            dropdownOpen: false
        }
    }

    generateNavLink(path) {
        return (
        <li>
            <NavLink to={path} onClick={() => this.toggleNav()}>
                {paths[path].name}
            </NavLink>
        </li>);
    }

    toggleNav() {
        this.setState({dropdownOpen: !this.state.dropdownOpen});
    }

    render() {
        return (
            <div id="nav">
                <div id="name">
                    Spanish Trail Property Owners Association
                </div>
                <div id="nav-image">
                    <img alt="Spanish Trail Welcome Sign" src={WelcomeSign}/>
                </div>
                <div id="larger">
                    <ul id="nav-list">
                        {this.generateNavLink("/home")}
                        {this.generateNavLink("/aboutUs")}
                        {this.generateNavLink("/forms")}
                        {this.generateNavLink("/news")}
                        {this.generateNavLink("/contactUs")}
                    </ul>
                </div>
                <div id="smaller">
                    <span id="nav-toggle">
                        <FontAwesomeIcon icon={faBars} size="2x" onClick={() => this.toggleNav()}/>
                    </span>
                    <ul id="nav-list" hidden={!this.state.dropdownOpen}>
                        {this.generateNavLink("/home")}
                        {this.generateNavLink("/aboutUs")}
                        {this.generateNavLink("/forms")}
                        {this.generateNavLink("/news")}
                        {this.generateNavLink("/contactUs")}
                    </ul>
                </div>
            </div>
        );
    }
}

export default Nav;