import React from "react";

import "./news.scss";

class NewsComponent extends React.Component {
    render() {
        return (
            <div id="news">
                <h2>News</h2>
                <p>
                    We are looking for contributions to the  Newsletter for STPOA! Also,
                    we would like to revive the "Yard of the Month" program, but need a
                    volunteer to head that effort up. Please contact a board member if
                    you have an interest in either of these missions.
                </p>
            </div>
        );
    }
}

export default NewsComponent;