import React from "react";
import {getFiles, getFile} from "../misc/api";
import {
    Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel,
} from 'react-accessible-accordion';
import {indicate} from "./indicator";

import "./forms.scss";

class Forms extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            fetched: false
        }
    }

    downloadFile(fileKey) {
        indicate("Downloading", "Complete", async () => {
            await getFile(fileKey)
                .then(async data => {
                    if (data && data["url"]) {
                        await fetch(data["url"])
                            .then(res => res.blob())
                            .then(blob => {
                                let a = document.createElement("a");
                                a.href = window.URL.createObjectURL(blob);
                                a.download = fileKey;
                                a.click();
                            });
                    }
                });
        })
            .then(() => console.log(`Downloaded file ${fileKey}`))
            .catch((err => console.log(`File download failed: ${err}`)));

    }

    async componentDidMount() {
        await indicate("Retrieving Files", "Complete", async () => {
            return await getFiles();
        })
            .then((data) => {
                data.sort(this.compareItems)
                this.setState({fetched: true, base: data});
            })
            .catch((err => console.log(`Failed to retrieve files: ${err}`)));
    }

    compareItems(item1, item2) {
        if (item1.type === "FILE" && item2.type === "FOLDER") {
            return -1;
        } else if (item1.type === "FOLDER" && item2.type === "FILE") {
            return 1;
        }
        // Same types
        return item1.name.localeCompare(item2.name);
    }

    generateItemJsx(item, index) {
        if (item.type === "FILE") {
            return this.generateFileJsx(item, index)

        } else if (item.type === "FOLDER") {
            return this.generateFolderJsx(item, index)
        }
    }

    generateFileJsx(file, index) {
        return (
            <div key={file.name + index} className="file">
                <span className="clickable-text"
                      onClick={() => this.downloadFile(file.key)}>
                    {file.name}
                </span>
            </div>)
    }

    generateFolderJsx(folder, index) {
        folder.items.sort(this.compareItems)

        return (
            <div key={folder.name + index} className="folder">
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            {folder.name}
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        {folder.items.map((component, index) => {
                            return this.generateItemJsx(component, index)
                        })}
                    </AccordionItemPanel>
                </AccordionItem>
            </div>)
    }


    render() {
        return (
            <div id="forms">
                <h2>Forms</h2>
                {this.state.fetched ? (<Accordion allowMultipleExpanded={true} allowZeroExpanded={true}>
                    {this.state.base.map((component, index) => {
                        return this.generateItemJsx(component, index)
                    })}
                </Accordion>) : (<span/>)}
            </div>);
    }
}

export default Forms;