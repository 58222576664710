import React from "react";
import {sendEmail} from "../misc/api";
import {ErrorMessage, Field, Form, Formik} from "formik";
import {indicate} from "./indicator";

import "./contactUs.scss";

class ContactUs extends React.Component {

    submit(values) {
        return indicate("Sending", "Sent", async () => {
            return await sendEmail(values);
        });
    }

    validate(values) {
        const errors = {};
        // Name
        if (!values.name) {
            errors.name = "Required";
        }

        // Email Address
        if (!values.emailAddress) {
            errors.emailAddress = "Required";

        } else if (!(/^[A-Z\d._%+-]+@[A-Z\d.-]+\.[A-Z]{2,}$/i.test(values.emailAddress))) {
            errors.emailAddress = "Invalid email address";
        }

        // Subject
        if (!values.subject) {
            errors.subject = "Required";
        }

        // Message
        if (!values.message) {
            errors.message = "Required";
        }

        return errors;
    }

    render() {
        return (
            <div id="contactUs">
                <h2>Contact Us</h2>
                <p>
                    STPOA<br/>
                    P O Box 4<br/>
                    Granbury TX 76048<br/>
                </p>
                <p>
                    Questions or recommendations for the Board? Updates, questions, or suggestions for the website?<br/>
                    Mail to above address or email us below!
                </p>
                <p>
                    Thank you!
                </p>
                <Formik initialValues={{
                    name: "",
                    emailAddress: "",
                    subject: "",
                    message: "",
                    files: []
                }}
                        validate={values => this.validate(values)}
                        onSubmit={(values, {setSubmitting, resetForm}) => {
                            return this.submit(values)
                                .then(_ => {
                                    setSubmitting(false);
                                    resetForm();
                                })
                                .catch((err => console.log(`Email submission failed: ${err}`)));
                        }}
                >
                    {({isSubmitting, setFieldValue, handleSubmit}) => (
                        <Form id="contact-form" onSubmit={handleSubmit}>
                            <div className="form-line">
                                <Field id="name" type="text" name="name" placeholder="Name"/>
                                <ErrorMessage name="name" component="div" className="input-error"/>
                            </div>
                            <div className="form-line">
                                <Field id="email-address" type="email" name="emailAddress" placeholder="Email Address"/>
                                <ErrorMessage name="emailAddress" component="div" className="input-error"/>
                            </div>
                            <div className="form-line">
                                <Field id="subject" type="text" name="subject" placeholder="Subject"/>
                                <ErrorMessage name="subject" component="div" className="input-error"/>
                            </div>
                            <div className="form-line">
                                <Field id="message" component="textarea" name="message" placeholder="Message"/>
                                <ErrorMessage name="message" component="div" className="input-error"/>
                            </div>
                            {/*<div className="form-line">*/}
                            {/*    <Field id="files" type="file" name="files">*/}
                            {/*        {({field, _, __}) => {*/}
                            {/*            return (*/}
                            {/*                <div>*/}
                            {/*                    <label id="files-button" htmlFor="files-input">*/}
                            {/*                        <FontAwesomeIcon icon={faFile} size="sm"/> Choose Files*/}
                            {/*                    </label>*/}
                            {/*                    <label id="selected-files">*/}
                            {/*                        <ul>*/}
                            {/*                            {Array.from(field.value)*/}
                            {/*                                .map((file, i) => <li key={i}>{file.name}</li>)}*/}
                            {/*                        </ul>*/}
                            {/*                    </label>*/}
                            {/*                    <input id="files-input" type="file" multiple*/}
                            {/*                           onChange={(event) => {*/}


                            {/*                               const files = event.currentTarget.files*/}
                            {/*                               for (let file of files) {*/}
                            {/*                                   const fr = new FileReader();*/}
                            {/*                                   fr.readAsBinaryString(file)*/}
                            {/*                                   fr.onprogress = data => {*/}
                            {/*                                       if (data.lengthComputable) {*/}
                            {/*                                           // {*/}
                            {/*                                           //     name: file.name,*/}
                            {/*                                           //     percent: Math.floor((data.loaded / data.total) * 100)*/}
                            {/*                                           // }*/}
                            {/*                                       }*/}
                            {/*                                   }*/}
                            {/*                               }*/}
                            {/*                               setFieldValue("files", files)*/}
                            {/*                           }}/>*/}
                            {/*                </div>*/}
                            {/*            );*/}
                            {/*        }}*/}
                            {/*    </Field>*/}
                            {/*</div>*/}
                            <div className="form-line">
                                <button id="submit" type="submit" disabled={isSubmitting}>
                                    Send
                                </button>
                            </div>
                        </Form>
                    )}
                </Formik>

                {/*<div id="email-attachment" className="input-line">
                        <label id="attachment-button" htmlFor="attachment-input">
                            <FontAwesomeIcon icon={faFile} size="sm"/> Choose Files
                        </label>
                        <label id="attachment-selected-files">
                            {Array.from(this.state.files)
                                .map((file, i) => <div key={i}>{file.name}</div>)}
                        </label>
                        <input id="attachment-input" type="file" name="files" multiple onChange={this.handleChange}/>
                    </div>

                    <input id="email-submit" type="button" value="Send" onClick={this.submit}/>
                </form>*/}
            </div>
        );
    }
}

export default ContactUs;