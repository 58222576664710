import {API} from "aws-amplify";

let api = "stpoaapi";

let apiCallWrapper = async (apiCall) => {
    return await apiCall
        .then((data) => {
            return Promise.resolve(data)
        })
        .catch(err => {
            return Promise.reject(err?.response?.data?.errorId)
        });
}

let getFiles = async () => {
    return await apiCallWrapper(API.get(api, "/files", {}));
}

let getFile = async (fileKey) => {
    let uri = encodeURIComponent(fileKey);
    return await apiCallWrapper(API.get(api, `/file/${uri}`, {}));

}

let sendEmail = async (values) => {
    return await apiCallWrapper(API.post(api, "/email", {body: values}))
}

export {getFiles, getFile, sendEmail}