import Home from "../components/home";
import AboutUs from "../components/aboutUs";
import Forms from "../components/Forms";
import News from "../components/news";
import ContactUs from "../components/contactUs";

export const paths = {
    "/home": {
        component: Home, name: "Home"
    },
    "/aboutUs": {
        component: AboutUs, name: "About Us"
    },
    "/forms": {
        component: Forms, name: "Forms & More"
    },
    "/news": {
        component: News, name: "News"
    },
    "/contactUs": {
        component: ContactUs, name: "Contact Us"
    }
}