import React from "react";

import "./aboutUs.scss";

class AboutUs extends React.Component {
    render() {
        return (
            <div id="aboutUs">
                <h4>Board</h4>
                <table id="board-members">
                    <tbody>
                    <tr>
                        <td>President</td>
                        <td>Karen Morrison</td>
                    </tr>
                    <tr>
                        <td>Vice President</td>
                        <td>Richie Mills</td>
                    </tr>
                    <tr>
                        <td>Treasurer</td>
                        <td>John Cowden</td>
                    </tr>
                    <tr>
                        <td>Secretary</td>
                        <td>Matthew Lease</td>
                    </tr>
                    <tr>
                        <td>Director</td>
                        <td>Bob Moore</td>
                    </tr>
                    <tr>
                        <td>Director</td>
                        <td>Jacquel Rodriguez </td>
                    </tr>
                    <tr>
                        <td>Director</td>
                        <td>Shawn Cox</td>
                    </tr>
                    </tbody>
                </table>

                <p>
                    <span className="bold">STPOA Board Meetings</span> are <span
                    className="bold italic">normally</span> held
                    on the 2nd Tuesday of each month at 7:00 PM. All are welcome!
                </p>
            </div>
        );
    }
}

export default AboutUs;
