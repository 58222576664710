import React from "react";
import Amplify, {Auth} from "aws-amplify"
import awsmobile from "../aws-exports";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect
} from "react-router-dom";
import Nav from "./nav";
import {paths} from "../misc/constant";

import "./app.scss";
import {IndicatorWidget} from "./indicator";

Amplify.configure(awsmobile);
Auth.configure(awsmobile)

class App extends React.Component {

    generateRoute(path) {
        return (<Route exact path={path} component={paths[path].component}/>);
    }

    render() {
        return (
            <div id="app">
                <IndicatorWidget/>
                <Router>
                    <div id="nav-pane">
                        <Nav/>
                    </div>
                    <div id="content-pane">
                        <div id="content">
                            <Switch>
                                {this.generateRoute("/home")}
                                {this.generateRoute("/aboutUs")}
                                {this.generateRoute("/forms")}
                                {this.generateRoute("/news")}
                                {this.generateRoute("/contactUs")}
                                <Redirect to="/home"/>
                            </Switch>
                        </div>
                    </div>
                </Router>
            </div>
        );
    }
}

export default App;
