import React from "react";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faCheck, faSpinner, faBan} from "@fortawesome/free-solid-svg-icons"

import "./indicator.scss";
import {Subject} from "rxjs";

const DEAD = "DEAD";
const IN_PROGRESS = "IN_PROGRESS";
const COMPLETE = "COMPLETE";
const FAILURE = "FAILURE";

let subject = new Subject();

let defaultState = {
    status: DEAD,
    text: "",
    timeoutId: undefined
}

export class IndicatorWidget extends React.Component {
    subject;

    constructor(props) {
        super(props);
        this.state = {...{}, ...defaultState};
    }

    componentDidMount() {
        this.subject = subject.subscribe((data) => {
            this.indicate(data)
        });
    }

    componentWillUnmount() {
        this.subject.unsubscribe();
    }

    reset() {
        if (this.timeoutId) {
            clearTimeout(this.timeoutId);
        }
        this.setState({...{}, ...defaultState});
    }

    indicate(data) {
        let timeoutId = undefined;
        if (data.status === COMPLETE) {
            timeoutId = setTimeout(() => this.reset(), 3000);

        } else if (data.status === FAILURE) {
            timeoutId = setTimeout(() => this.reset(), 30000);
        }
        data.timeoutId = timeoutId;

        this.setState({...this.state, ...data});
    }

    generateIconTextJsx() {
        if (this.state.status === IN_PROGRESS) {
            return (
                <div>
                    <FontAwesomeIcon className="icon" icon={faSpinner} spin/>
                    <span className="message">{this.state.text ? this.state.text : "Working"}</span>
                </div>);

        } else if (this.state.status === COMPLETE) {
            return (
                <div>
                    <FontAwesomeIcon className="icon" icon={faCheck}/>
                    <span className="message">{this.state.text ? this.state.text : "Complete"}</span>
                </div>);

        } else if (this.state.status === FAILURE) {
            return (
                <div>
                    <FontAwesomeIcon className="icon" icon={faBan}/>
                    <span className="message">Error {this.state.text ? this.state.text : "Unknown"}</span>
                </div>);
        }
    }

    render() {
        return (
            <div id="indicator" className={this.state.status} onClick={() => this.reset()}>
                {this.generateIconTextJsx()}
            </div>
        );
    }
}

export async function indicate(inProgressText, completeText, promise) {
    subject.next({
        status: IN_PROGRESS,
        text: inProgressText
    });
    return await promise()
        .then(data => {
            subject.next({
                status: COMPLETE,
                text: completeText
            });
            return Promise.resolve(data);
        })
        .catch(errorId => {
            subject.next({
                status: FAILURE,
                text: errorId
            });
            return Promise.reject(errorId);
        });
}
